<template>
  <div>
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">
          Общая информация
        </h4>
      </div>
      <div class="card-body">
        <b-form @submit.prevent>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Тип тренировки"
              >
                <b-form-input
                  v-model="workout.type"
                  placeholder="Тип тренировки"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </div>
    </div>

    

    <div class="card">
      <div class="card-header" />
      <div class="card-body">
        <b-row v-if="workout.icon">
          <b-col md="6">
            <span class="b-avatar badge-minimal badge-light-primary" style="width: 100px; height: 100px; margin-bottom: 20px;">
              <span class="b-avatar-img">
                <img :src="workout.icon.fullPath" alt="avatar">
              </span>
            </span>
          </b-col>
        </b-row>
        <div class="upload-wrap">
          <file-uploader
            v-model="photo"
            :default="photo"
            title="Фото"
          />
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header" />
      <div class="card-body">
        <h4 class="card-title">
          Описание
        </h4>
        <div class="my-1">
          Описание тренировки
        </div>
        <quill-editor
          v-model="workout.description"
        />
      </div>
    </div>

    <b-col>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        type="submit"
        variant="primary"
        class="mr-1 save-button"
        :disabled="isloading"
        @click="saveItem"
      >
        <b-spinner
          v-if="isloading"
          small
        />
        {{ isloading ? 'Сохранение...' : 'Сохранить' }}
      </b-button>
    </b-col>
  </div>
</template>

<script>
import {
  BButton,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BSpinner,
  VBPopover,
} from 'bootstrap-vue'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { Russian } from 'flatpickr/dist/l10n/ru.js'
import crud_module from '../../../libs/crud_module'
import FileUploader from '@/global-components/FileUploader'

const workout_module = crud_module({
  entity_name: 'workout',
})

export default {

  name: 'CategoryPage',
  components: {
    quillEditor,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BSpinner,
    FileUploader
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  data() {
    return {
      config: {
        allowInput: true,
        altFormat: 'd.m.Y H:i',
        altInput: true,
        enableTime: true,
        dateFormat: 'Z',
        locale: Russian, // locale for this instance only
      },
      isloading: false,
      workout: {},
      photo: null
    }
  },
  async created() {
    if (this.$route.params.id === 'new') {
      return
    }
    try {
      await workout_module.fetchItem(this.$route.params.id, {
        query: {
          admin: '1',
        },
      }).then(result => {
        this.workout = result.data
        this.photo = result.data.fileId
      })
    } catch (e) {
      console.error(e)
    }
  },
  methods: {
    saveItem() {
      if (this.$route.params.id === 'new') {
        this.create()
      } else {
        this.update()
      }
    },
    async update() {
      try {
        console.log(this.photo)
        await workout_module.updateItem(this.workout.id, {...this.workout, fileId: this.photo})
          .then(response => {
            this.isloading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Уведомление',
                icon: 'BellIcon',
                text: 'Тренировка сохранена',
                variant: 'success',
              },
            })
            this.$router.push({ name: 'workouts' })
            return response
          })
          .catch(error => {
            this.isloading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Уведомление',
                icon: 'BellIcon',
                text: error.response.data.message,
                variant: 'danger',
              },
            })
          })
      } catch (error) {
        console.log(error)
        this.isloading = false
      }
    },
    async create() {
      try {
        console.log(this.photo)
        await workout_module.createItem({...this.workout, fileId: this.photo})
          .then(response => {
            this.isloading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Уведомление',
                icon: 'BellIcon',
                text: 'Тренировка добавлена',
                variant: 'success',
              },
            })
            this.$router.push({ name: 'workouts' })
            return response
          })
          .catch(error => {
            this.isloading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Уведомление',
                icon: 'BellIcon',
                text: error.response.data.message,
                variant: 'danger',
              },
            })
          })
      } catch (e) {
        this.isloading = false
        console.error(e)
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
